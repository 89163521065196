<template lang="pug">
  .period(
    v-if="!isEmpty(period.$model)"
    :class="{ invalid: period.$error }"
  )
    AppNumberInput.input.start-date.year(
      allow-empty
      :max="2100"
      :value="period.start_date.year.$model"
      :invalid="period.start_date.year.$error"
      @blur="validate"
      @input="handleChangeField('start_date', 'year', $event)"
    )
    | {{ $t("plan_settings.settings_by_period.table_columns.target_period.year") }}
    AppNumberInput.input.start-date.month(
      allow-empty
      :max="12"
      :value="period.start_date.month.$model"
      :invalid="period.start_date.month.$error"
      @blur="validate"
      @input="handleChangeField('start_date', 'month', $event)"
    )
    | {{ $t("plan_settings.settings_by_period.table_columns.target_period.month") }}
    AppNumberInput.input.start-date.day(
      allow-empty
      :max="31"
      :value="period.start_date.day.$model"
      :invalid="period.start_date.day.$error"
      @blur="validate"
      @input="handleChangeField('start_date', 'day', $event)"
    )
    | {{ $t("plan_settings.settings_by_period.table_columns.target_period.day") }}
    |〜
    AppNumberInput.input.end-date.year(
      allow-empty
      :max="2100"
      :value="period.end_date.year.$model"
      :invalid="period.end_date.year.$error"
      @blur="validate"
      @input="handleChangeField('end_date', 'year', $event)"
    )
    | {{ $t("plan_settings.settings_by_period.table_columns.target_period.year") }}
    AppNumberInput.input.end-date.month(
      allow-empty
      :max="12"
      :value="period.end_date.month.$model"
      :invalid="period.end_date.month.$error"
      @blur="validate"
      @input="handleChangeField('end_date', 'month', $event)"
    )
    | {{ $t("plan_settings.settings_by_period.table_columns.target_period.month") }}
    AppNumberInput.input.end-date.day(
      allow-empty
      :max="31"
      :value="period.end_date.day.$model"
      :invalid="period.end_date.day.$error"
      @blur="validate"
      @input="handleChangeField('end_date', 'day', $event)"
    )
    | {{ $t("plan_settings.settings_by_period.table_columns.target_period.day") }}
</template>

<script>
  import { isEmpty, isNumber, set } from "lodash-es"

  export default {
    components: {
      AppNumberInput: () => import("@/components/elements/AppNumberInput")
    },

    props: {
      period: {
        type: Object,
        default: () => new Object()
      }
    },

    methods: {
      isEmpty,

      validate() {
        if (
          isNumber(this.period.start_date.year.$model) &&
          isNumber(this.period.start_date.month.$model) &&
          isNumber(this.period.start_date.day.$model) &&
          isNumber(this.period.end_date.year.$model) &&
          isNumber(this.period.end_date.month.$model) &&
          isNumber(this.period.end_date.day.$model)
        ) {
          this.period.$touch()
        } else {
          this.period.$reset()
        }
      },

      handleChangeField(period, field, value) {
        set(this.period, `${period}.${field}.$model`, value)
        this.$emit("update-period")
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/common.sass"

  .period
    display: flex
    align-items: center

    &.invalid
      ::v-deep
        input
          +default-invalid-input

    .input
      ::v-deep
        input
          height: 2rem
          text-align: center
          margin: 0 4px
          padding: 5px !important

      &.year
        ::v-deep
          input
            width: 56px
            font-size: 0.8rem
            height: 2rem

    .actions
      display: flex

      & > *
        cursor: pointer

      .add-period, .remove-period
        width: 20px
        height: 20px
        margin-left: 5px
        padding: 3px
        color: $default-purple

        &:hover
          color: darken($default-purple, 10%)

      .add-period
        border-radius: 50%
        border: 1px solid $default-purple

        &.no-remove
          margin-left: 25px
</style>
